/* eslint-disable no-console */
import React, { Component } from "react";
import { DropdownItem, /*Tab*/ } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import {
  DashboardLayout,
  StyledTabs,
  // HeaderWrapper,
  OverlayDashboard,
  DropDownCompanayContainer,
  YearDropDown,
  NewLabel,
  DownloadWrapper,
  YearDropdown,
  DropdownWrapper
} from "./styles";
import EngagementSectionV2 from "../EngagementSectionV2";
import FitnessTab from "../FitnessTabV2";
import ChampionSectionV2 from "../ChampionSectionV2";
import Waiting from "../Waiting";
import StyledDropDown from "../DropDown";
import NameHeader from "../NameHeader/NameHeaderNew";
import {
  fetchDashBoardAPI,
  fetchFitnessAPI,
  fetchChampionAPI,
  fetchReportTabAction,
  setSelectedYear,
  fetchAdminAPI,
  updateCompanyInfo,
  fetchCompanyInfo,
} from "../../redux/actions";
import EngagementCSVModal from "../EngagementCSVModal";
import WellnessAdmins from "../WellnessAdmins";
import CompanyInfoUpdate from "./companyInfoPopupMain";
import { DownloadIconNew, InitiativeDownloadIcon } from "../../utils/icons";
import HoverButton from "../common/HoverButton";
import {TabsStyle, TabsWrapper} from '../../components/common/commonStyles';
import { Dropdown } from "react-bootstrap";
import { RecipeCSVdownload, FitnessvideoCSVdownload, ArticleCSVdownload } from '../../redux/constants/apiConstants';
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/config";
import CSVList from '../AttendeesModal/CSVList';

const RecipeHeaders = [
  { label: "Name", key: "name" },
  { label: "Video URL", key: "video_url" },
  { label: "Total Time(Mins)", key: "total_time" },
  { label: "Meal Type", key: "meal_type" },
  { label: "Cook Time(Mins)", key: "cook_time" },
  { label: "Prep Time(Mins)", key: "prep_time" },
  { label: "Category Tags", key: "category_tags" },
  { label: "Last Updated", key: "last_updated" }
];

const FitnessHeaders = [
  { label: "Title", key: "title" },
  { label: "Video Url", key: "video_url" },
  { label: "Fitness Level", key: "fitness_level" },
  { label: "Muscle Group", key: "muscle_group" },
  { label: "Video Category", key: "video_category" },
  { label: "Last Updated", key: "Last Updated" },
];

const ArticleHeaders = [
  { label: "Title", key: "title" },
  { label: "Category Name", key: "category_name" },
  { label: "Last Updated", key: "Last_Updated" }
];
class CompanyDashBoard extends Component {
  constructor(props) {
    super(props);

    const selectedCompanyJSON = localStorage.getItem('selectedCompany');
    const localCompanyInfo = JSON.parse(selectedCompanyJSON);
    const localCompanyId = localCompanyInfo?.id;
    const localCompanyName = localCompanyInfo?.name;

    this.state = {
      companyId: localCompanyId ? parseInt(localCompanyId, 10) : props.companyInfo.id,
      companyName: localCompanyName ? localCompanyName : props.companyInfo.name,
      printEnrolledUsers: false,
      selectedTabId: 1,
      showCompanyInfoPopup: false,
      showArticle: false,
      showFitnessVideo: false,
      showRecipeVideo: false,
      getRecipeData:[],
      getFitnessData: [],
      getArticleData: []
    };
  }

  componentDidMount() {
    const { fetchDashBoardAPI } = this.props;
    let currentYear = new Date().getFullYear();
    fetchDashBoardAPI(this.state.companyId, currentYear);
  }

  downloadReportsAPI=()=>{
    this.fetchRecipeData();
    this.fetchFitnessData();
    this.fetchArticleData();
  }

  fetchArticleData = async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + ArticleCSVdownload;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if(data.status === 'success'){
              this.setState({getArticleData: data.data[0] }
              );
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  fetchRecipeData= async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + RecipeCSVdownload;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if(data.status === 'success'){
              this.setState({getRecipeData: data.data }
              );
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  fetchFitnessData = async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + FitnessvideoCSVdownload;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if(data.status === 'success'){
              this.setState({getFitnessData: data.data[0] }
              );
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  printRecipeAction = () => {
    let data = [];
    const { getRecipeData } = this.state;
    getRecipeData && getRecipeData?.length > 0 && getRecipeData.map((best) => {
      let obj = {};
      obj['name'] = best['name'];
      obj['video_url'] = best['video_url'];
      obj['total_time'] = best['total_time'];
      obj['last_updated']=best['last_updated']
      obj['meal_type']=best['meal_type']
      obj['cook_time']=best['cook_time']
      obj['prep_time']=best['prep_time']
      obj['category_tags']=best.category_tags.map((data)=>(data?.recipe_tag))
      data.push(obj);
    });
    return data;
  };

  printFitnessAction = () => {
    let data = [];
    const { getFitnessData } = this.state;
    getFitnessData && getFitnessData?.length > 0 && getFitnessData.map((best) => {
      let obj = {};
      obj['title'] = best['title'];
      obj['video_url'] = best['video_url'];
      obj['fitness_level'] = best['fitness_level'];
      obj['muscle_group']=best['muscle_group']
      obj['Last Updated']=best['Last Updated']
      obj['video_category']=best['video_category']
      data.push(obj);
    });
    return data;
  };

  printArticleAction = () => {
    let data = [];
    const { getArticleData } = this.state;
    getArticleData && getArticleData?.length > 0 && getArticleData.map((best) => {
      let obj = {};
      obj['title'] = best['title'];
      obj['Last_Updated'] = best['Last_Updated'];
      obj['category_name'] = best['category_name'];
      data.push(obj);
    });
    return data;
  };

  getMenuItems = () => {
    const { companies } = this.props;
    return companies && companies.map((company) => company.company_name);
  };

  onSelect = (value) => {
    const {
      companies,
      setCompany,
      fetchDashBoardAPI,
      fetchFitnessAPI,
      fetchChampionAPI,
      fetchAdminAPI,
      companyBrandingCallBack,
      fetchCompanyInfo,
    } = this.props;
    const id = companies.filter((company) => company.company_name === value)[0]
      .id;
    const comapanyStartDate = companies.filter(
      (company) => company.company_name === value
    )[0].company_start_date;
    const biometricStatus = companies.filter(
      (company) => company.company_name === value
    )[0].show_biometric;

    const selectedCompany = {
      id: id,
      name: value,
      companyStartDate: comapanyStartDate,
      showBiometricStatus: biometricStatus
    };
  
    localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany));

    setCompany(id, value, comapanyStartDate, biometricStatus);
    fetchCompanyInfo(id);
    companyBrandingCallBack(id);
    let currentYear = new Date().getFullYear();
    this.setState(
      {
        companyId: id,
        companyName: value
      },
      () => {
        this.state.selectedTabId === 1
          ? fetchDashBoardAPI(this.state.companyId, currentYear)
          : this.state.selectedTabId === 2
            ? fetchFitnessAPI(this.state.companyId, currentYear)
            : this.state.selectedTabId === 3
              ? fetchChampionAPI(this.state.companyId)
              : fetchAdminAPI(this.state.companyId);
      }
    );
  };
  updatePrintState = () => {
    this.setState((prevState) => ({
      printEnrolledUsers: !prevState.printEnrolledUsers,
    }));
  };

  toggleTab = (active) => {
    const {
      fetchFitnessAPI,
      fetchChampionAPI,
      fetchAdminAPI,
      selectedYearInfo,
      fetchDashBoardAPI,
    } = this.props;
    this.setState({ selectedTabId: active });
    active === 1
      ? fetchDashBoardAPI(this.state.companyId, selectedYearInfo)
      : active === 2
        ? fetchFitnessAPI(this.state.companyId, selectedYearInfo)
        : active === 3
          ? fetchChampionAPI(this.state.companyId)
          : fetchAdminAPI(this.state.companyId);
  };
  updateDropdown = (year) => {
    const {
      fetchFitnessAPI,
      fetchReportTabAction,
      fetchDashBoardAPI,
      setSelectedYear,
    } = this.props;
    const { companyId, selectedTabId } = this.state;
    fetchReportTabAction(companyId, year);
    setSelectedYear(year);
    selectedTabId === 1
      ? fetchDashBoardAPI(companyId, year)
      : selectedTabId === 2
        ? fetchFitnessAPI(companyId, year)
        : null;
  };

  showCompanyInfoPopup = (id) => {
    this.setState(
      () => ({
        showCompanyInfoPopup: true,
      }),
      () => this.props.fetchCompanyInfo(id)
    );
  };

  hideCompanyInfoPopup = () => {
    this.setState({
      showCompanyInfoPopup: false,
    });
  };

  updateInformationApi = (obj) => {
    this.setState(
      () => ({
        showCompanyInfoPopup: false,
      }),
      () => this.props.updateCompanyInfo(obj)
    );
  };

  downloadRecipeList = (bool) => {
    this.setState({
      showRecipeVideo: bool
    })
  };

  downloadFitnessList = (bool) => {
    this.setState({
      showFitnessVideo: bool
    })
  };

  downloadArticleList = (bool) => {
    this.setState({
      showArticle: bool
    })
  };

  render() {
    const {
      csvButton,
      networkError,
      wellnessChampions,
      wellnessAdmin,
      topChallenges,
      topEvents,
      newUsers,
      leastActiveUsers,
      mostActiveUsers,
      topDevices,
      fitnessGoals,
      topActivities,
      weekExercise,
      wellnessInitiatives,
      companyInitiatives,
      employeeCount,
      role,
      companyInfo,
      enrolledUsers,
      activeMinutes,
      eligibleEmployee,
      history,
      selectedYearInfo,
      yearList,
      fitnessTabLoading,
      fitnessUsers,
      showCompaniesDropdown,
      DownloadFileAccess,
      companyDetailsInfo,
      colorInfo,
      companyInfoData,
    } = this.props;
    const {
      companyId,
      printEnrolledUsers,
      selectedTabId,
      showCompanyInfoPopup,
      showFitnessVideo,
      showRecipeVideo,
      showArticle,
      getRecipeData,
      getFitnessData,
      getArticleData
    } = this.state;
    const fileTitle = (
      companyInfo && companyInfo.name !== null ? companyInfo.name : ""
    ).replace(/ /g, "_");
    if (networkError) {
      return <Waiting />;
    }
    return (
      <OverlayDashboard showOverlay={employeeCount && csvButton}>
        {printEnrolledUsers ? "" : !!employeeCount && csvButton && <Waiting />}
        <DashboardLayout>
          <NameHeader />
          <TabsWrapper
            style={{ maxWidth: "1246px" }}
            margin={selectedTabId===1?"15px auto 40px auto":selectedTabId===2?"15px auto 25px auto":selectedTabId===3?"15px auto 0px auto":"15px auto 0px auto"}
          >
            <TabsStyle active={this.state.selectedTabId===1} onClick={()=>this.toggleTab(1)}>
              Engagement
            </TabsStyle>
            <TabsStyle active={this.state.selectedTabId===2} onClick={()=>this.toggleTab(2)}>
              Fitness
            </TabsStyle>
            <TabsStyle active={this.state.selectedTabId===3} onClick={()=>this.toggleTab(3)}>
              Champions
            </TabsStyle>
            <TabsStyle active={this.state.selectedTabId===4} onClick={()=>this.toggleTab(4)}>
              Admins
            </TabsStyle>
            <div style={{display:"flex", margin:"auto 0px auto auto"}}>
              {(this.state.selectedTabId === 3? false
                : DownloadFileAccess) && (
                <DownloadWrapper admin={role == "ADMIN"}>
                  <HoverButton
                    width={"40px"}
                    height={"40px"}
                    title={"Download"}
                    fillOpacity={1}
                    disabled={false}
                    backgroundColor={"#f0f6f8"}
                    backgroundColorActive={"#005c87"}
                    activeColor={"#619ab5"}
                    inActiveColor={"white"}
                    svgPath={<DownloadIconNew />}
                    onClick={() => {this.updatePrintState();}}
                  />
                </DownloadWrapper>
              )}
            </div>
            {role == "ADMIN"&&<div style={{display:"flex", margin:this.state.selectedTabId === 3?"auto 0px auto auto": "auto 0px auto 5px"}}>
              {(this.state.selectedTabId === 3)? false
                :(
                  <DropdownWrapper><div style={{padding: "0px"}}>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <div style={{}}>
                          <DownloadWrapper admin={role == "ADMIN"}>
                            <HoverButton
                              width={"40px"}
                              height={"40px"}
                              title={"Download"}
                              fillOpacity={1}
                              disabled={false}
                              backgroundColor={"#f0f6f8"}
                              backgroundColorActive={"#005c87"}
                              activeColor={"#619ab5"}
                              inActiveColor={"white"}
                              svgPath={<InitiativeDownloadIcon />}
                              onClick={()=>this.downloadReportsAPI()}
                            />
                          </DownloadWrapper>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={()=>this.downloadArticleList(true)} >
                          Download All Article Details
                        </Dropdown.Item>
                        <hr/>
                        <Dropdown.Item onClick={()=>this.downloadFitnessList(true)} >
                          Download All Fitness Video Details
                        </Dropdown.Item>
                        <hr/>
                        <Dropdown.Item onClick={()=>this.downloadRecipeList(true)} >
                          Download All Recipe Details
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div></DropdownWrapper>
                )}
            </div>}
            <YearDropdown><div style={{display:"flex", margin:"auto 0px auto 15px"}}>
              {(this.state.selectedTabId === 1 ||
                this.state.selectedTabId === 2) && (
                <React.Fragment>
                  <NewLabel role={"role"}>Year</NewLabel>
                  <YearDropDown
                    inputpadding="9px"
                    width="100%"
                    type="button"
                    label="year"
                    title={
                      selectedYearInfo && (
                        <div className="title">{selectedYearInfo}</div>
                      )
                    }
                    id="year"
                    role={role}
                  >
                    {yearList.map((list, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => this.updateDropdown(list)}
                      >
                        {list}
                      </DropdownItem>
                    ))}
                  </YearDropDown>
                </React.Fragment>
              )}
            </div></YearDropdown>
            <div style={{display:"flex", margin:"auto 0px auto -15px"}}>
              {" "}
              {showCompaniesDropdown && (
                <DropDownCompanayContainer width={"unset"}>
                  <StyledDropDown
                    title={this.state.companyName || get(companyInfo, "name", "Filter companies")}
                    MenuItems={this.getMenuItems()}
                    isCompanyDashboard={true}
                    onSelect={this.onSelect}
                    style={{ margin: "0px", width: "100%" }}
                  />
                </DropDownCompanayContainer>
              )}
            </div>      
          </TabsWrapper>
          {this.state.selectedTabId === 1 ? (
            <EngagementSectionV2
              companyId={companyInfo.id}
              wellnessChampions={wellnessChampions}
              topChallenges={topChallenges}
              topEvents={topEvents}
              newUsers={newUsers}
              wellnessInitiatives={wellnessInitiatives}
              companyInitiatives={companyInitiatives}
              employeeCount={employeeCount || 0}
              updatePrintState={this.updatePrintState}
              eligibleEmployee={eligibleEmployee}
              selectedYearInfo={selectedYearInfo}
              history={history}
              role = {role}
              showCompanyInfoPopup={this.showCompanyInfoPopup}
              hideCompanyInfoPopup={this.hideCompanyInfoPopup}
            />
          ) :this.state.selectedTabId === 2 ? (
            <FitnessTab
              mostActiveUsers={mostActiveUsers}
              leastActiveUsers={leastActiveUsers}
              fitnessGoals={fitnessGoals}
              topDevices={topDevices}
              companyId={companyId}
              topActivities={topActivities}
              weekExercise={weekExercise}
              activeMinutes={activeMinutes}
              selectedYearInfo={selectedYearInfo}
              fitnessTabLoading={fitnessTabLoading}
              yearList={yearList}
            />):
            this.state.selectedTabId === 3 ? (
              <ChampionSectionV2 wellnessChampions={wellnessChampions} />):
              <WellnessAdmins wellnessAdmin={wellnessAdmin} />}
          {printEnrolledUsers && (
            <EngagementCSVModal
              showModal={printEnrolledUsers}
              onHide={this.updatePrintState}
              enrolledUsers={enrolledUsers}
              fileTitle={fileTitle}
              yearList={yearList}
              companyId={companyId}
              csvButton={csvButton}
              role={role}
              selectedTabId={selectedTabId}
              fitnessUsers={fitnessUsers}
              StyledTabs={StyledTabs}
            />
          )}
          {showCompanyInfoPopup && (
            <CompanyInfoUpdate
              show={showCompanyInfoPopup}
              hidePopup={this.hideCompanyInfoPopup}
              updateInformationApi={this.updateInformationApi}
              companyId={companyId}
              companyName={
                companyDetailsInfo && companyDetailsInfo.company_name
              }
              companyLogo={companyDetailsInfo && companyDetailsInfo.header_logo}
              companyColor={
                colorInfo &&
                colorInfo?.color_branding &&
                colorInfo?.color_branding[1]?.color_code
              }
              companyInfoData={companyInfoData}
            />
          )}
        </DashboardLayout>
        {((showFitnessVideo&&getFitnessData?.length>0) ||(showRecipeVideo&&getRecipeData?.length>0)||(showArticle&&getArticleData?.length>0))&&
          <CSVList
            Title={showFitnessVideo?"List of All Fitness Videos":showArticle?"List of All Articles": "List of All Recipe Videos"}
            removeCSVData={showFitnessVideo?this.downloadFitnessList:showArticle?this.downloadArticleList:this.downloadRecipeList}
            headers={showFitnessVideo?FitnessHeaders:showArticle?ArticleHeaders:RecipeHeaders}
            data={showFitnessVideo?this.printFitnessAction():showArticle?this.printArticleAction(): this.printRecipeAction()}
          />
        }
      </OverlayDashboard>
    );
  }
}
CompanyDashBoard.defaultProps = {
  companyInfo: { id: 1, name: "" },
};
CompanyDashBoard.propTypes = {
  wellnessChampions: PropTypes.array,
  wellnessAdmin: PropTypes.array,
  networkError: PropTypes.string,
  companies: PropTypes.array,
  companyInfo: PropTypes.object.isRequired,
  setCompany: PropTypes.func.isRequired,
  topChallenges: PropTypes.array,
  topEvents: PropTypes.array,
  newUsers: PropTypes.array,
  mostActiveUsers: PropTypes.array,
  leastActiveUsers: PropTypes.array,
  fitnessGoals: PropTypes.array,
  topDevices: PropTypes.array,
  topActivities: PropTypes.array,
  weekExercise: PropTypes.object,
  wellnessInitiatives: PropTypes.array,
  companyInitiatives: PropTypes.array,
  role: PropTypes.string.isRequired,
  employeeCount: PropTypes.number,
  history: PropTypes.object,
  fetchDashBoardAPI: PropTypes.func,
  fetchFitnessAPI: PropTypes.func,
  fetchChampionAPI: PropTypes.func,
  fetchAdminAPI: PropTypes.func,
  enrolledUsers: PropTypes.array,
  fitnessUsers: PropTypes.array,
  csvButton: PropTypes.bool,
  activeMinutes: PropTypes.object,
  eligibleEmployee: PropTypes.any,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  fitnessTabLoading: PropTypes.bool,
  fetchReportTabAction: PropTypes.func,
  groupSummaryYearList: PropTypes.array,
  setSelectedYear: PropTypes.func,
  showCompaniesDropdown: PropTypes.bool,
  DownloadFileAccess: PropTypes.bool,
  companyBrandingCallBack: PropTypes.func,
  updateCompanyInfo: PropTypes.func,
  companyDetailsInfo: PropTypes.object,
  colorInfo: PropTypes.object,
  fetchCompanyInfo: PropTypes.func,
  companyInfoData: PropTypes.object,
  localCompanyId: PropTypes.number,
  localCompanyName: PropTypes.string
};

const mapStateToProps = (state) => ({
  wellnessChampions: state.companyDashboard.wellnessChampions,
  wellnessAdmin: state.companyDashboard.wellnessAdmin,
  networkError: state.companyDashboard.networkError,
  topChallenges: state.companyDashboard.topChallenges,
  topEvents: state.companyDashboard.topEvents,
  newUsers: state.companyDashboard.newUsers,
  mostActiveUsers: state.companyDashboard.mostActiveUsers,
  leastActiveUsers: state.companyDashboard.leastActiveUsers,
  fitnessGoals: state.companyDashboard.fitnessGoals,
  topDevices: state.companyDashboard.topDevices,
  topActivities: state.companyDashboard.topActivities,
  weekExercise: state.companyDashboard.weekExercise,
  wellnessInitiatives: state.companyDashboard.wellnessInitiatives,
  companyInitiatives: state.companyDashboard.companyInitiatives,
  employeeCount: state.companyDashboard.employeeCount,
  enrolledUsers: state.companyDashboard.enrolledUsers,
  fitnessUsers: state.companyDashboard.fitnessUsers,
  csvButton: state.companyDashboard.csvButton,
  activeMinutes: state.companyDashboard.activeMinutes,
  eligibleEmployee: state.companyDashboard.eligibleEmployee,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  fitnessTabLoading: state.companyDashboard.fitnessTabLoading,
  companyDetailsInfo: state.profileData.companyDetails,
  colorInfo: state.wellnessDashboard.companyBrandingDetails,
  companyInfoData: state.companyDashboard.companyInfoData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashBoardAPI: (id, year) => dispatch(fetchDashBoardAPI(id, year)),
  fetchFitnessAPI: (id, year) => dispatch(fetchFitnessAPI(id, year)),
  fetchChampionAPI: (id) => dispatch(fetchChampionAPI(id)),
  fetchAdminAPI: (id) => dispatch(fetchAdminAPI(id)),
  fetchReportTabAction: (id, year) => dispatch(fetchReportTabAction(id, year)),
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  updateCompanyInfo: (obj) => dispatch(updateCompanyInfo(obj)),
  fetchCompanyInfo: (id) => dispatch(fetchCompanyInfo(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashBoard);